.riderMain {
    font-size: 30px;
    margin-top: 50px;
    align-items: center;
    // display: flex;
    // flex-direction: column;
    padding-bottom: 8px !important;
}

.addRiderform {
    background-color: white;
    margin-top: 6%;
    margin-left: 2%;
    margin-right: 1%;
    margin-bottom: 1%;
    width: 60%;
    // padding: 2%;
    display: flex;
    border-radius: 12px;
    flex-direction: column;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Adjust values as needed */
}

.addRiderTextHeader {
    background-color: #1E9CED; 
    border-top-left-radius: 12px; 
    border-top-right-radius: 12px;
}

.addRiderText {
    font-size: 20px !important;
    font-weight: 600 !important;
    margin-top: 10px !important;
    margin-left: 15px !important;
    margin-bottom: 12px !important;
    padding-top: 5px;
    padding-bottom: '5px';
    color: white;
}

.addRiderformText{
    margin-bottom: 10px !important;
}

.updateRiderformText{
    margin-top: 15px !important;
}

.riderListTable{
    width: 96%;
    overflow: hidden;
    margin-left: 2%;
    // margin-right: 1%;
    margin-bottom: 2% !important;
    margin-right: -22px !important;
    margin-top: 3%;
    border-radius: 12px !important;
    align-self: self-start;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
}

.riderListTableHeader {
    align-items: center; 
    justify-content: space-between;
    background-color: #1E9CED; 
    padding-top: 7px; 
    padding-bottom: 7px; 
}

.riderRecordsText {
    margin-left: 2% !important;
    font-size: 20px !important;
    font-weight: 700 !important;
}

.updateRiderDetailsHeader {
    justify-content: space-between; 
    background-color: #1E9CED;
    margin: -32px;
    margin-bottom: 2px; 
    border-top-left-radius: 12px; 
    border-top-right-radius: 12px;
}

.UpdateHeaderCloseIcon {
    margin-right: 10px; 
    margin-top: 10px; 
    cursor: pointer; 
    color: white; 
    font-size: 28px;
}

.loader {
    color: white !important;
}

.tableLoader {
    align-self: center;
    margin-left: 350%;
    margin-top: 20%;
}

.noRecordsFound {
    font-size: 20px !important;
    margin-top: 20px;
    font-weight: 600 !important;
    margin-bottom: 10px !important;
    color: red
}