.version-control-main {
    font-size: 30px;
    margin-top: 50px;
    align-items: center;
    // display: flex;
    // flex-direction: column;
    padding-bottom: 8px !important;
}

.add-version-control-form {
    background-color: white;
    margin-top: 6%;
    margin-left: 2%;
    margin-right: 1%;
    margin-bottom: 1%;
    // width: 30%;
    // padding: 2%;
    display: flex;
    border-radius: 12px;
    flex-direction: column;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Adjust values as needed */
}

.add-version-header {
    background-color: #1E9CED;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.add-version-text {
    font-size: 20px !important;
    font-weight: 600 !important;
    margin-top: 10px !important;
    margin-left: 15px !important;
    margin-bottom: 12px !important;
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
}

.version-list-table {
    // margin-left: 2%;
    margin-right: 1%;
    // margin-bottom: 2% !important;
    // margin-right: -22px !important;
    margin-top: 3%;
    border-radius: 12px !important;
    // align-self: self-start;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
}

.version-list-table-header {
    background-color: #1E9CED; 
    padding: 7px;
    padding-left: 13px; 
    border-top-left-radius: 12px; 
    border-top-right-radius: 12px; 
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
}

.version-list-search {
    border: 1px solid grey !important;
    margin-right: 2% !important;
    border-radius: 20px !important;
    background-color:whitesmoke !important; 
}

.update-version-header {
    justify-content: space-between;
    background-color: #1E9CED; 
    margin: -32px; 
    margin-bottom: 2px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px; 
}

.update-version-close-icon {
    margin-right: 10px; 
    margin-top: 10px; 
    cursor: pointer; 
    color: white; 
    font-size: 30px !important;
}

.update-version-form-text-field {
    margin-top: 28px !important;
}

.update-version-form-text-field1 {
    margin-top: 30px !important;
}

.loader {
    color: white !important;
}

.tableLoader {
    align-self: center;
    margin-left: 350%;
    margin-top: 20%;
}

.noRecordsFound {
    font-size: 20px !important;
    margin-top: 10px !important;
    font-weight: 600 !important;
    margin-bottom: 10px !important;
    color: red
}