.organizationMain{
    font-size: 30px;
    margin-top: 80px;
    // margin-left: 2%;
    // margin-right: 2%;
    align-items: center;
    padding-bottom: 8px;
}

.addOrganizationForm {
    background-color: white;
    margin-top: 1%;
    margin-left: 2%;
    margin-right: 1%;
    margin-bottom: 1%;
    width: 60%;
    // padding: 2%;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Adjust values as needed */
}

.addOrganizationFormHeader {
    background-color: #1E9CED;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px; 
}

.addOrganiZationText{
    // align-self: center;
    font-size: 20px !important;
    font-weight: 600 !important;
    margin-left: 15px !important;
    padding-bottom: 10px;
    padding-top: 10px;
    color: white;
    // margin-bottom: 25px !important;
}

.organizationListTable{
    width: 96.2%; 
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
    align-self: self-start;
    margin-top: 3%;
    margin-left: 2%;
    margin-right: 1%;
    border-radius: 12px !important;
    margin-bottom: 2% !important;
}

.organizationListHeader {
    background-color: #1E9CED; 
    padding: 10px;
}

.organizationText{
    font-size: 20px !important;
    // margin-top: 10px !important;
    color: white;
    font-weight: 600 !important;
    padding-bottom: 5px; 
    padding-top: 5px;
}

.updateOrgModalHeader {
    justify-content: space-between; 
    background-color: #1E9CED; 
    margin: -32px; 
    margin-bottom: 20px; 
    border-top-left-radius: 12px; 
    border-top-right-radius: 12px;
}

.updateRiderformText {
    margin-top: 25px !important;
}

.updateOrgModalCloseIcon {
    margin-right: 10px; 
    margin-top: 10px; 
    cursor: pointer; 
    color: white; 
    font-size: 30px !important;
}

.loader {
    color: white !important;
}

.tableLoader {
    align-self: center;
    margin-left: 340%;
    margin-top: 20%;
}