@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

$green-100: #061711;
$green-90: #0a271c;
$green-80: #0f3a2a;
$green-70: #134d38;
$green-60: #186146;
$green-base: #1d7454;
$green-50: #438b71;
$green-40: #68a28d;
$green-30: #8ebaaa;
$green-20: #b4d1c6;
$green-10: #d2e3dd;
$primary-btn: #3EB489;

$white: #fff;
$black: #000;

$sub-text: #6a6a6a;

.img-fluid{
    max-width: 100%;
    height: auto;
}

.text-center{
    text-align: center !important;
}

.text-end{
    text-align: end !important;
}

.disabled{
    background-color: $green-20 !important;
    color: $white !important;
}

.error-message{
    margin: 0;
    padding: 0;
    position: relative;
    top: -5px;
    color: red;
    font-size: 11px;
}

.open{
    background-color: #000 !important;
    display: none;
}

.active{
    background-color: #061711 !important;
}

.d-flex{
    display: flex !important;
}