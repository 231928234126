.navbar{
    // background-color: $green-base !important;
    height: 60px;
    .profileBox{
        display: flex;
        background-color: white;
        border-radius: 75px;
        height: 33px;
        position: relative;
        top: 5px;
        margin-right: 30px;
        cursor: pointer;
        .profileSmall{
            position: relative;
            height: 25px;
            top: 5px;
            padding: 0 5px 0 5px;
        }
        .email{
            position: relative;
            top: 5px;
            color: black;
            margin: 0;
            font-size: 15px;
            padding: 0 10px 0 0px;
        }
    }
    .profileSmallMob{
        cursor: pointer;
        position: relative;
        top: 5px;
    }
    .bell-icon{
        position: relative;
        top: 2px;
        right: 17px;
        cursor: pointer;
    }
    .bell-iconMob{
        cursor: pointer;
        position: relative;
        top: 4px;
        right: 12px;
    }
    .navLogo{
        height: 37px;
        width: 95px;
    }
    .navLogoMob{
        height: 27px;
        width: 68px;
        align-self: center !important;
    }
    .navHeader{
        margin-left: 13px;
        font-size: 20px;
    }
    .navHeaderMob{
        margin: 0;
        padding: 0;
    }
}