.signup-page{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // background-color: $green-base;
    background-color: #1E9CED;
    .signupForm{
        h1{
            margin: 0;
            padding: 0;
            // color: $sub-text;
        }
        a{
            color: #F5831F;
        }
        .smallPtext{
            margin: 0;
            padding: 0;
            position: relative;
            font-weight: 400;
        }
        .sendOtp, .reSendOtp, .verifyGst{
            font-weight: 500;
            position: relative;
            // background-color: $primary-btn;
            background-color: #1E9CED;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 7px;
            border-bottom-right-radius: 7px;
        }
        .reSendOtp{
            background-color: #F5831F;
            flex-direction: column;
            // color: $white;
            color: white;
        }
    }
}

.signup-logos{
    .riderText{
        font-size: 38px;
        font-weight: 800;
        color: #f5fbfe;
        text-align: center;
    }
    .clientPanel{
        // position: relative;
        font-size: 40px;
        text-align: right;
        margin-right: 5%;
        font-weight: 800;
        color: #f5fbfe;
    }
}

.wrongOtp:focus{
    border-color: red !important; 
}

.signup{
    padding: 21px;
    border-radius: 10px;
    // background-color: $white;
    background-color: white;
    justify-content: center;
}

@media screen and ( min-width: 1400px ) {
    .signBox{
        width: 900px !important;
    }
    .signImage{
        height: 451px;
        width: 425px;
    }
    .signup-logos{
        .clientPanel{
            top: 20px;
            right: -15px;
        }
    }
    .signupForm{
        padding: 10px;
        h1{
            font-size: 22px;
        }
        p{
            font-size: 11px;
        }
        .smallPtext{
            font-size: 9px;
            top: -5px;
            // color: $sub-text;
        }
        .reSendOtp{
            top: 0px;
            left: -2px;
            height: 84%;
            p{
                margin: 0;
                padding: 0;
                font-size: 7.3px !important;
            }
            .timer{
                font-size: 15px !important;
                margin: 0;
            }
        }
        .sendOtp{
            top: 0px;
            left: -2px;
            height: 84%;
            font-size: 10px;
        }
        .verifyGst{
            top: 0px;
            left: -2px;
            height: 84%;
            width: 100%;
            font-size: 10px;
        }
        .signBtnBox{
            justify-content: center !important;
        }
    }
}