@import './global.scss';

// Components
// @import './components/PrimaryButton.scss';
// @import './components/LoginTextInput.scss';
// @import './components/Navbar.scss';
// @import './components/SideBar.scss';
// @import './components/ProfileProgressCard.scss';
// @import './components/ProductCard.scss';

// Screens
@import './screens/riderManagement.scss';
// @import './screens/dashboard.scss';
// @import './screens/products.scss';


body{
  background-color: $green-10 !important;
}

.full-height{
  height: 100vh !important;
}

.container-fluid {
  margin: 2rem auto;
  text-align: center;
}